import React from 'react';
import './style/matrix-effect.scss';

const chars = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "А", "а", "Б", "б", "В", "в", "Г", "г", "Д", "д", "Е", "е", "Ё", "ё", "Ж", "ж", "З", "з", "И", "и", "Й", "й", "К", "к", "Л", "л", "М", "м", "Н", "н", "О", "о", "П", "п", "Р", "р", "С", "с", "Т", "т", "У", "у", "Ф", "ф", "Х", "х", "Ц", "ц", "Ч", "ч", "Ш", "ш", "Щ", "щ", "Ъ", "ъ", "Ы", "ы", "Ь", "ь", "Э", "э", "Ю", "ю", "Я", "я"];
export default class MatrixEffect extends React.Component {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  interval: any;

  constructor(props: {}) {
    super(props);
    this.canvasRef = React.createRef();
    this.interval = null;
  }

  componentDidMount() {
    const canvas = this.canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const step = window.innerWidth < 1000 ? 30 : 30
    const w = canvas.width = document.body.offsetWidth;
    const h = canvas.height = document.body.offsetHeight;
    const cols = Math.floor(w / step) + 1;
    const ypos = Array(cols).fill(0);

    ctx.fillStyle = '#000';
    ctx.fillRect(0, 0, w, h);

    const matrix = () => {
      ctx.fillStyle = '#0001';
      ctx.fillRect(0, 0, w, h);

      ctx.fillStyle = 'rgb(4,38,95)';
      ctx.fillStyle = 'rgb(8,159,101)';
      ctx.fillStyle = 'rgb(4,83,54)';
      if (window.innerWidth > 1000) {
        const gradient = ctx.createLinearGradient(0, 0, w, 0);
        gradient.addColorStop(0, 'rgb(4,38,95)');
        gradient.addColorStop(0.5, 'rgb(8,159,101)');
        gradient.addColorStop(1, 'rgb(4,83,54)');
        ctx.fillStyle = gradient;
      }
      ctx.font = '25pt monospace';

      ypos.forEach((y, ind) => {
        const text = chars[Math.floor(Math.random() * chars.length)]; //String.fromCharCode(Math.random() * 128);
        const x = ind * step;
        ctx.fillText(text, x, y);
        if (y > 100 + Math.random() * 10000) ypos[ind] = 0;
        else ypos[ind] = y + step;
      });
    }

    const handleResize = () => {
      if (!canvas) return;
      canvas.width = document.body.offsetWidth;
      canvas.height = document.body.offsetHeight;
      ctx.fillStyle = '#000';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    };

    window.addEventListener('resize', handleResize);
    this.interval = setInterval(matrix, window.innerWidth < 1000 ? 100 : 50);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const canvas = this.canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    canvas.width = document.body.offsetWidth;
    canvas.height = document.body.offsetHeight;
    ctx.fillStyle = '#000';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  };

  render() {
    return (
        <div className={"matrix-effect"}><canvas ref={this.canvasRef} id="canv" /></div>
    );
  }
}