import React from "react";
import "./about_us.scss";
import GlitchHeader from "../../components/cool_stuff/glitch_header/GlitchHeader";
import TypedText from "../../components/cool_stuff/typed_text/TypedText";


const aboutUsMessages = [
  "💡 Освещаем открытые технологии\n"+
  "📊 Развиваем открытые данные\n"+
  "🎯 Проводим мероприятия для развития открытых технологий и сообщества\n"+
  "🌍 Рады всем!"
]

export default class AboutUs extends React.Component<any, any> {
  typingTimer: NodeJS.Timeout | null = null;
  chatContainerRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: any) {
    super(props);

    this.state = {
      messages: [
        // "🚀 Прямо сейчас идет хакатон по разметке даннных, в котором может принять участие каждый! ✨",
        "🚀 27.12 планируется хакатон по разметке даннных, в котором может принять участие каждый! ✨",
      ],
      displayedAnnouncement: "",
      currentIndex: 0,
      showAboutUs: false
    };

    this.startTypingAnimation = this.startTypingAnimation.bind(this);
  }

  

  startTypingAnimation = () => {
    const typeNextChar = () => {
      if (this.state.currentIndex < this.state.messages[this.state.currentIndex].length) {
        this.setState({
          displayedAnnouncement: this.state.messages[this.state.currentIndex].slice(0, this.state.currentIndex + 1),
          currentIndex: this.state.currentIndex + 1
        });
        this.typingTimer = setTimeout(typeNextChar, 50);
      }
    };
    typeNextChar();
  };

  scrollToBottom = () => {
    if (this.chatContainerRef.current) {
      this.chatContainerRef.current.scrollTop = this.chatContainerRef.current.scrollHeight;
    }
  }
  
  render() {
    const me = this;
    return <div className={"open-source"}>
      <GlitchHeader content={"Кластер"} />
      <br/>
      <div  className={"chat-container"}
            ref={me.chatContainerRef}>
        { me.state.messages.map((message: string, index: number) => {
          if (message.startsWith("me:")) {
            return <span className={"me"}>{message.slice(3)}</span>
          } else {
            return <TypedText message={message} key={index} onFinishTyping={me.scrollToBottom} />
          }
          })}
      </div>
      <br/>
      <button className={"sport"}
              onClick={() => {
                me.setState({messages: [...me.state.messages, "me:👉 Участвовать в хакатоне", ...["Вы переходите на следующий уровень"]]}, 
                () => {
                  setTimeout(() => {
                    me.props.setSide(1);
                  }, 2000);
                })
              }} 
              style={{textShadow: "0px 0px 5px rgba(0,0,200, 0.2)"}}>👉 Участвовать в хакатоне</button>
      <button  
        onClick={() => {me.setState({messages: [...me.state.messages, "me:👉 Узнать больше о кластере", ...aboutUsMessages]});}} 
        style={{backgroundColor: "transparent", color: "rgba(255, 255,255, 0.7)", textShadow: "0px 0px 5px rgba(0,0,200, 0.2)"}}>👉 Узнать больше о кластере</button>
    </div>
  }
}