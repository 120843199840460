import React from "react";
import "./left_menu.scss";

interface LeftMenuProps {
  elements: any[];
  activeSide: number;
}

interface LeftMenuState {
  activeMenu: boolean;
}

export default class LeftMenu extends React.Component<LeftMenuProps, LeftMenuState> {
  timeoutToClose: any;
  timeoutToIgnore: any;
  ignoreInput: boolean = false;

  constructor(_props: LeftMenuProps) {
    super(_props);
    this.state = {
      activeMenu: false
    };
  }

  render() {
    const me = this;

    return <>
        <div className={`menu-list`}>
          {
            me.props.elements.map((_e, _eIndex) => {
              if (_e[1].indexOf("СКРЫТЬ") !== -1) return <></>;
              return <div onClick={() => {
                _e[0]();
              } } className={`item-container`}>
                <div className={`item ${(_e[1] === me.props.activeSide) ? 'active-item' : ''}`}>
                  <i className={_e[2]}></i>
                  {_e[1]}
                </div>
              </div>
            })
          }
      </div>
    </>;
  }
}