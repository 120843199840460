import React from "react";
import "./article.scss";

export default class PrivacyPolicy extends React.Component<any, any> {
  render() {
    return <div className={"article"}>
      <h1>Политика конфиденциальности</h1>
      <h2>Основные положения</h2>
      <p>Настоящая политика определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных в системе «вИИзуал»
      (далее <strong>предприятие</strong>), которое является оператором персональных данных.</p>
      <p>Политика разработана в целях обеспечения реализации требований законодательства Российской
      Федерации в области обработки персональных данных, направленного на обеспечение защиты прав и свобод человека и
      гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни,
      личную и семейную тайну, в частности в целях защиты от несанкционированного доступа и неправомерного
      распространения персональных данных, обрабатываемых в информационных системах предприятия.</p>
      <p>Политика действует в отношении информации, которую предприятие получает о субъекте
      персональных данных в процессе предоставления услуг или исполнения договорных обязательств, а также в процессе
      трудовых отношений с предприятием.</p>
      <p>Настоящая Политика раскрывает состав субъектов
      персональных данных, принципы, порядок и условия обработки персональных данных сотрудников предприятия и иных лиц,
      чьи персональные данные обрабатываются предприятием, с целью обеспечения защиты прав и свобод человека и
      гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни,
      личную и семейную тайну.</p>
      <p>Персональные данные являются конфиденциальной, строго
      охраняемой информацией и на них распространяются все требования, установленные внутренними документами предприятия
      по защите конфиденциальной информации.</p>
      <h2>Категории персональных данных</h2>
      <p>Перечень персональных данных, обрабатываемых на предприятии, формируется в соответствии с федеральным законодательством о
      персональных данных и другими нормативными документами РФ.</p>
      <p>Сведениями, составляющими
      персональные данные, является любая информация, относящаяся к прямо или косвенно определенному или определяемому
      физическому лицу (субъекту персональных данных).</p>
      <p>В зависимости от субъекта
      персональных данных, предприятие обрабатывает персональные данные следующих категорий субъектов персональных
      данных:</p>
      <ul>
        <li>персональные данные сотрудника предприятия, необходимые предприятию в связи с трудовыми отношениями и
          касающиеся конкретного сотрудника предприятия;
        </li>
        <li>персональные данные соискателя на вакантную должность;</li>
        <li>персональные данные руководителя или сотрудника юридического лица, являющегося контрагентом предприятия,
          необходимые предприятию для выполнения своих обязательств в рамках договорных отношений с контрагентом и для
          выполнения требований законодательства Российской Федерации;
        </li>
        <li>персональные данные физических лиц – контрагентов предприятия.</li>
      </ul>
      <h2>Цели обработки персональных данных</h2>
      <p>Предприятие осуществляет обработку персональных данных в следующих целях:</p>
      <ul>
        <li>заключения, исполнения и прекращения гражданско-правовых договоров с физическими, юридическим лицами,
          индивидуальными предпринимателями и иными лицами, в случаях, предусмотренных действующим законодательством;
        </li>
        <li>организации кадрового учета предприятия, обеспечения соблюдения законов и иных нормативно-правовых актов,
          заключения и исполнения обязательств по трудовым и гражданско-правовым договорам; ведения кадрового
          делопроизводства, содействия сотрудникам в трудоустройстве, обучении и продвижении по службе, пользования
          различного вида льготами, исполнения требований налогового законодательства в связи с исчислением и уплатой
          налога на доходы физических лиц, а также единого социального налога, пенсионного законодательства при
          формировании и представлении персонифицированных данных о каждом получателе доходов, учитываемых при
          начислении страховых взносов на обязательное пенсионное страхование и обеспечение, заполнения первичной
          статистической документации, в соответствии с Трудовым кодексом РФ, Налоговым кодексом РФ, федеральными
          законами, в частности: «Об индивидуальном (персонифицированном) учете в системе обязательного пенсионного
          страхования», «О персональных данных».
        </li>
      </ul>
      <h2>Сроки</h2>
      <p>Сроки обработки персональных данных определяются в соответствии со сроком
        действия договора с субъектом персональных данных, а также иными требованиями законодательства РФ.</p> <p
        >На предприятии создаются и хранятся документы, содержащие сведения о субъектах персональных
        данных. Требования к использованию на предприятии данных типовых форм документов установлены Постановлением
        Правительства РФ от 15.09.2008 №687 «Об утверждении Положения об особенностях обработки персональных данных,
        осуществляемой без использования средств автоматизации».</p> <h2>Права</h2> <p>Предприятие,
        являющееся оператором персональных данных, вправе:</p>
      <ul>
        <li>отстаивать свои интересы в суде;</li>
        <li>предоставлять персональные данные субъектов третьим лицам, если это предусмотрено действующим
          законодательством (налоговые, правоохранительные органы и др.);
        </li>
        <li>отказывать в предоставлении персональных данных в случаях предусмотренных законодательством;</li>
        <li>использовать персональные данные субъекта без его согласия, в случаях предусмотренных законодательством.
        </li>
      </ul>
      <p>Субъект, персональные данные которого обрабатываются на предприятии, имеет право:</p>
      <ul>
        <li>требовать уточнения своих персональных данных, их блокирования или уничтожения в случае, если персональные
          данные являются неполными, устаревшими, недостоверными, незаконно полученными или не являются необходимыми для
          заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;
        </li>
        <li>требовать перечень своих персональных данных, обрабатываемых предприятием и источник их получения;</li>
        <li>получать информацию о сроках обработки своих персональных данных, в том числе о сроках их хранения;</li>
        <li>требовать извещения всех лиц, которым ранее были сообщены неверные или неполные его персональные данные, обо
          всех произведенных в них исключениях, исправлениях или дополнениях;
        </li>
        <li>обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке
          неправомерные действия или бездействия при обработке его персональных данных;
        </li>
        <li>на защиту своих прав и законных интересов, в том числе на возмещение убытков и (или) компенсацию морального
          вреда в судебном порядке.
        </li>
      </ul>
      <h2>Принципы и условия обработки персональных данных</h2> <p>Обработка персональных данных на
        предприятии осуществляется на основе принципов:</p>
      <ul>
        <li>законности и справедливости целей и способов обработки персональных данных;</li>
        <li>соответствия целей обработки персональных данных целям, заранее определенным и заявленным при сборе
          персональных данных;
        </li>
        <li>соответствия объема и характера обрабатываемых персональных данных, способов обработки персональных данных
          целям обработки персональных данных;
        </li>
        <li>достоверности персональных данных, их достаточности для целей обработки, недопустимости обработки
          персональных данных, избыточных по отношению к целям, заявленным при сборе персональных данных;
        </li>
        <li>не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в
          целях, несовместимых между собой;
        </li>
        <li>хранения персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем
          этого требуют цели их обработки;
        </li>
        <li>уничтожения либо обезличивания по достижении целей обработки персональных данных или в случае утраты
          необходимости в их достижении.
        </li>
      </ul>
      <p>Обработка персональных данных осуществляется на основании условий, определенных
        законодательством Российской Федерации.</p> <h2>Обеспечение безопасности персональных данных</h2> <p
       >Предприятием выполняются необходимые организационные и технические меры для обеспечения
        безопасности персональных данных от несанкционированного (в том числе случайного) доступа, уничтожения,
        изменения, блокирования доступа и других несанкционированных действий.</p> <p>Для
        целенаправленного создания на предприятии неблагоприятных условий и труднопреодолимых препятствий для
        нарушителей, пытающихся осуществить несанкционированный доступ к персональным данным в целях овладения ими, их
        видоизменения, уничтожения, заражения вредоносной компьютерной программой, подмены и совершения иных
        несанкционированных действий на предприятии применяются следующие организационные и технические меры:</p>
      <ul>
        <li>назначение должностных лиц, ответственных за организацию обработки и защиты персональных данных;</li>
        <li>ограничение состава сотрудников, имеющих доступ к персональным данным;</li>
        <li>ознакомление сотрудников с требованиями федерального законодательства и нормативных документов предприятия
          по обработке и защите персональных данных;
        </li>
        <li>обеспечение учѐта и хранения материальных носителей информации и их обращения, исключающего хищение,
          подмену, несанкционированное копирование и уничтожение персональных данных; - определение угроз безопасности
          персональных данных при их обработке, формирование на их основе моделей угроз;
        </li>
        <li>реализация разрешительной системы доступа пользователей к информационным ресурсам, программно-аппаратным
          средствам обработки и защиты информации;
        </li>
        <li>регистрация и учѐт действий пользователей информационных систем персональных данных;</li>
        <li>парольная защита доступа пользователей к информационной системе персональных данных;</li>
        <li>осуществление антивирусного контроля, предотвращение внедрения в корпоративную сеть вредоносных программ
          (программ-вирусов) и программных закладок;
        </li>
        <li>централизованное управление системой защиты персональных данных.</li>
        <li>резервное копирование информации;</li>
        <li>обеспечение восстановления персональных данных, модифицированных или уничтоженных вследствие
          несанкционированного доступа к ним;
        </li>
        <li>обучение сотрудников, использующих средства защиты информации, применяемые в информационных системах
          персональных данных, правилам работы с ними;
        </li>
        <li>учѐт применяемых средств защиты информации, эксплуатационной и технической документации к ним;</li>
        <li>использование средств защиты информации, прошедших в установленном порядке процедуру оценки соответствия;
        </li>
        <li>проведение мониторинга действий пользователей, проведение разбирательств по фактам нарушения требований
          безопасности персональных данных;
        </li>
        <li>размещение технических средств обработки персональных данных, в пределах охраняемой территории;</li>
        <li>организация пропускного режима на территорию предприятия;</li>
        <li>поддержание технических средств охраны, сигнализации помещений в состоянии постоянной готовности;</li>
        <li>иные организационные и технические меры защиты персональных данных, предусмотренные нормативными документами
          РФ и внутренними документами предприятия.
        </li>
      </ul>
      <h2>Заключительные положения</h2> <p>Настоящая Политика является внутренним документом
        системы «КЛИИ», общедоступной и подлежит размещению на официальном сайте предприятия.</p> <p
       >Настоящая Политика подлежит изменению, дополнению в случае появления новых законодательных
        актов и специальных нормативных документов по обработке и защите персональных данных, но не реже одного раза в
        три года. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция
        Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики.</p> <p
       >Контроль исполнения требований настоящей Политики осуществляется лицом, ответственным за
        организацию обработки персональных данных на предприятии.</p> <p>Ответственность
        сотрудников предприятия, имеющих доступ к персональным данным, за невыполнение требований нормативных
        документов, регулирующих обработку и защиту персональных данных, определяется в соответствии с законодательством
        Российской Федерации и внутренними документами предприятия.</p></div>;
  }
}