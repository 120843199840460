import React from "react";
import "./community.scss";
import GlitchHeader from "../../components/cool_stuff/glitch_header/GlitchHeader";

export default class Community extends React.Component<any, any> {
  render() {
    const me = this;
    return <div className={"community"}>
      <GlitchHeader content={"Сообщество"} />
      <p>Чтобы участвовать в мероприятиях кластера<br/>нужно</p>
      <br/>
      
      <div className={"flex flex-row justify-between bevel"} style={{marginBottom: "20px"}}>
        {Array(3).fill(null).map((_, i) => (
          <i key={i} className={"fas fa-3x fa-arrow-down"} style={{
            color: "var(--sport-lime)",
            filter: "drop-shadow(0 0 5px var(--sport-lime))",
            animation: window.innerWidth > 1100 ? `pulse ${1 + i*0.2}s infinite, colorChange ${2 + i*0.5}s infinite` : undefined
          }}/>
        ))}
      </div>
      <button style={{background: "var(--sport-lime)", color: "var(--dart-black)"}} onClick={me.props.startRegistration} >зарегистрироваться</button>
      <div className={"flex flex-row justify-between"} style={{marginTop: "20px"}}>
        {Array(3).fill(null).map((_, i) => (
          <i key={i} className={"fas fa-3x fa-arrow-up"} style={{
            color: "var(--sport-lime)", 
            filter: "drop-shadow(0 0 5px var(--sport-lime))",
            animation: window.innerWidth > 1100 ? `pulse ${1 + i*0.2}s infinite, colorChange ${2 + (i+3)*0.5}s infinite` : undefined,
            transform: "rotate(45deg)"
          }}/>
        ))}
      </div>
    </div>
  }
}