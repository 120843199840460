import React from "react";
import "./style/right_part.scss";
import NewsPageContent from "./news/News";
import ApiProvider from "../../providers/api/ApiProvider";
import { ApiContext } from "../../providers/api/context/ApiContext";

export default class RightPart extends React.Component<any, any>{
    render() {
        const me = this;

        return <div className="right-part">
            <h3>Новости</h3>
            <ApiProvider>
                <ApiContext.Consumer>
                    {
                        ({api, user}) => {
                            return <NewsPageContent api={api!!}/>
                        }
                    }
                </ApiContext.Consumer>
            </ApiProvider>
        </div>
    }
}