import React from "react";
import {ApiEngine} from "api-engine";
import NewsItem from "./NewsItem";
import "./style/news-page-content.scss";
import ModalWindow from "../../modal/ModalWindow";

interface NewsPageContentProps {
    api: ApiEngine;
}

interface NewsPageContentState {
    news: any[];
    shownNewsIds: { [key: string]: boolean };
    activeNewsArticle: any | null;
    filter: string;
}

const news = [
    {
        alt_headline: "🏆 Поиск рукописного Грааля завершен!",
        article_abstract: "🏢 Офис",
        author_name: "👨‍💻 Константин Скобельцын",
        backstory: "🎯 ",
        body: "<span style='font-weight: 300'>Хакатон завершен!<br><p>Результатом стал самый большой датасет по разметке рукописных слов на русском языке (125к слов, собранных из 1.5к файлов)!</p>Победители и призеры отправились домой с заслуженными призами!</p><br/><p>Как это было: </p><video style='width: 100%; height: 200px;' controls=true src='https://клии.рф/api/uploads/hackathon_v1.mp4'></video><br>Разыграли: 🏆 <ol style='list-style: none; padding: 0; font-weight: 300'><li><b>🥇 1 место</b> - Клавиатура Akko MU01 Joy of Life ⌨️</li><li><b>🥈 2 место</b> - Клавиатура WOBKEY Rainy 75 Anodized Black Pro ⌨️</li><li><b>🥉 3 место</b> - HyperX Alloy Mars 2 ⌨️</li><li><b>4️⃣ 4 место</b> - Мышь Razer Naga X 🖱️</li><li><b>5️⃣ 5 место</b> - Мышь Logitech G502 X Black 🖱️</li><li>...а также много других призов</li></ol></span>",
        date_of_publication: "2988-03-14T07:02:01Z",
        id: "5cff28a0-245d-4428-9bcf-4bdb70785555",
        keywords: "видео",
        thumbnail_url: "/api/uploads/hackathon10.png",
        hideThumbnail: false,
        section_title: "🎯 Хакатоны",
        title: "🏆Хакатон 001 завершен",
        preview: "Хакатон 001 завершен"
    },
    {
        alt_headline: "🏆 Поиск рукописного Грааля идет полным ходом!",
        article_abstract: "🏢 Офис",
        author_name: "👨‍💻 Константин Скобельцын",
        backstory: "🎯 ",
        body: "<span style='font-weight: 300'>Долгожданный финал!<br><iframe width='100%' height='auto' src='https://rutube.ru/play/embed/7ed15884538ad46a94a7dd79e03470c9/' frameBorder='0' allow='clipboard-write; autoplay' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe><br><br>Разыгрываем: 🏆 <ol style='list-style: none; padding: 0; font-weight: 300'><li><b>🥇 1 место</b> - Клавиатура Akko MU01 Joy of Life ⌨️</li><li><b>🥈 2 место</b> - Клавиатура WOBKEY Rainy 75 Anodized Black Pro ⌨️</li><li><b>🥉 3 место</b> - HyperX Alloy Mars 2 ⌨️</li><li><b>4️⃣ 4 место</b> - Мышь Razer Naga X 🖱️</li><li><b>5️⃣ 5 место</b> - Мышь Logitech G502 X Black 🖱️</li><li>...а также много других призов</li></ol></span>",
        date_of_publication: "2988-03-14T07:02:01Z",
        id: "5cff28a0-245d-4428-9bcf-4bdb70785555",
        keywords: "видео",
        thumbnail_url: "/api/uploads/hackathon9.png",
        hideThumbnail: false,
        section_title: "🎯 Хакатоны",
        title: "🏆 Трансляция финала",
        preview: "Финал хакатона"
    },
    {
        alt_headline: "🏆 Поиск рукописного Грааля идет полным ходом!",
        article_abstract: "🏢 Офис",
        author_name: "👨‍💻 Константин Скобельцын",
        backstory: "🎯 ",
        body: "<span style='font-weight: 300'>Долгожданный финал!<br><br></span>Завтра, в 15:30 в здании IT парка по адресу Петербургская 52 (<a href='https://yandex.ru/maps/-/CHAFuSia/' style='text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000,1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000; color: var(--sport-lime);' target='_blank'>ссылка на карту</a>),<br>подведем итоги хакатона!<br><br>Разыгрываем: 🏆 <ol style='list-style: none; padding: 0; font-weight: 300'><li><b>🥇 1 место</b> - Клавиатура Akko MU01 Joy of Life ⌨️</li><li><b>🥈 2 место</b> - Клавиатура WOBKEY Rainy 75 Anodized Black Pro ⌨️</li><li><b>🥉 3 место</b> - HyperX Alloy Mars 2 ⌨️</li><li><b>4️⃣ 4 место</b> - Мышь Razer Naga X 🖱️</li><li><b>5️⃣ 5 место</b> - Мышь Logitech G502 X Black 🖱️</li><li>...а также много других призов</li></ol></span>",
        date_of_publication: "2988-03-14T07:02:01Z",
        id: "5cff28a0-245d-4428-9bcf-4bdb70785555",
        keywords: "видео",
        thumbnail_url: "/api/uploads/hackathon9.png",
        hideThumbnail: false,
        section_title: "🎯 Хакатоны",
        title: "🏆 Финал",
        preview: "Финал хакатона"
    },
    {
        alt_headline: "🏆 Поиск рукописного Грааля идет полным ходом!",
        article_abstract: "🏢 Офис",
        author_name: "👨‍💻 Константин Скобельцын",
        backstory: "🎯 ",
        body: "<span style='font-weight: 300'>Пришлось снова поменять призы, так как пары клавиатур тоже не было в наличии<br><br><video style='width: 100%; height: 200px;' controls=true src='https://клии.рф/api/uploads/keyboards.mp4'></video></span><br>Разыгрываем: 🏆 <ol style='list-style: none; padding: 0; font-weight: 300'><li><b>🥇 1 место</b> - Клавиатура Akko MU01 Joy of Life ⌨️</li><li><b>🥈 2 место</b> - Клавиатура WOBKEY Rainy 75 Anodized Black Pro ⌨️</li><li><b>🥉 3 место</b> - Keychron K3 Blue Switch ⌨️</li><li><b>4️⃣ 4 место</b> - Мышь Razer Naga X 🖱️</li><li><b>5️⃣ 5 место</b> - Мышь Logitech G502 X Black 🖱️</li></ol></span>",
        date_of_publication: "2988-03-14T07:02:01Z",
        id: "5cff28a0-245d-4428-9bcf-4bdb70789555",
        keywords: "видео",
        thumbnail_url: "/api/uploads/hackathon8.png",
        hideThumbnail: false,
        section_title: "🎯 Хакатоны",
        title: "🏆 Призы",
        preview: "Пришлось снова поменять призы, так как пары клавиатур тоже не было в наличии"
    },
    {
        alt_headline: "🏆 Поиск рукописного Грааля идет полным ходом!",
        article_abstract: "🏢 Офис",
        author_name: "👨‍💻 Константин Скобельцын",
        backstory: "🎯 ",
        body: "<span style='font-weight: 300'>Записали скринкаст, чтобы было легче начать:<br><br><video style='width: 100%; height: 200px;' preview='/api/uploads/hackathon7.png' controls=true src='https://клии.рф/api/uploads/hack_v1.mp4'></video></span>",
        date_of_publication: "2988-03-14T07:02:01Z",
        id: "5cff28a0-245d-4428-9bcf-4bdb70789555",
        keywords: "видео",
        thumbnail_url: "/api/uploads/hackathon7.png",
        hideThumbnail: true,
        section_title: "🎯 Хакатоны",
        title: "📺 Туториал",
        preview: "Записали видео-туториал для участников хакатона"
    },
    {
        alt_headline: "🏆 Поиск рукописного Грааля идет полным ходом!",
        article_abstract: "🏢 Офис",
        author_name: "👨‍💻 Константин Скобельцын",
        backstory: "🎯 Определелили список призов",
        body: "<span style='font-weight: 300'>Немного подвинули дату финала - <b style='background-color: #ffeb3b; color: var(--black);'>01 декабря 2024</b><br/><br/><span style='background-color: #ffeb3b;; color: var(--black);'>Регистрация на хакатон будет открыта до самого завершения</span>, так как критерием оценки является количество и качество контрибьютов.<br><br>Разыгрываем: 🏆 <ol style='list-style: none; padding: 0; font-weight: 300'><li><b>🥇 1 место</b> - Клавиатура Wobkey Crush 80 Reboot Pro Black ⌨️</li><li><b>🥈 2 место</b> - Клавиатура HyperX Alloy Mars 2 ⌨️</li><li><b>🥉 3 место</b> - Keychron K3 Blue Switch ⌨️</li><li><b>4️⃣ 4 место</b> - Мышь Razer Naga X 🖱️</li><li><b>5️⃣ 5 место</b> - Мышь Logitech G502 X Black 🖱️</li></ol></span>",
        date_of_publication: "2988-03-14T07:02:01Z",
        id: "5cff28a0-245d-4428-9bcf-4bdb70789555",
        keywords: "🎮 Хакатон; 🏅 Соревнования; 🤖 ИИ; 📊 Датасеты; ",
        lead: "🏁 Дата финала!",
        section_title: "🎯 Хакатоны",
        thumbnail_url: "/api/uploads/hackathon6.png",
        title: "🏁 Дата финала"
    },
    {
        alt_headline: "🏆 Поиск рукописного Грааля идет полным ходом!",
        article_abstract: "🏢 Офис",
        author_name: "👨‍💻 Константин Скобельцын",
        backstory: "🎯 Определелили список призов",
        body: "<span style='font-weight: 300'>К сожалению, Varmillo Panda не оказался в наличии, мы немного корректируем призовой фонд хакатона! 🏆 <ol style='list-style: none; padding: 0; font-weight: 300'><li><b>🥇 1 место</b> - Клавиатура Wobkey Crush 80 Reboot Pro Black ⌨️</li><li><b>🥈 2 место</b> - Клавиатура HyperX Alloy Mars 2 ⌨️</li><li><b>🥉 3 место</b> - Keychron K3 Blue Switch ⌨️</li><li><b>4️⃣ 4 место</b> - Мышь Razer Naga X 🖱️</li><li><b>5️⃣ 5 место</b> - Мышь Logitech G502 X Black 🖱️</li></ol></span>",
        date_of_publication: "2988-03-14T07:02:01Z",
        id: "5cff28a0-245d-4428-9bcf-4bdb70789555",
        keywords: "🎮 Хакатон; 🏅 Соревнования; 🤖 ИИ; 📊 Датасеты; 🎁 Призовой фонд",
        lead: "💎 Ценный лут!",
        section_title: "🎯 Хакатоны",
        thumbnail_url: "/api/uploads/hackathon4.png",
        title: "🎁 Призы"
    },
    {
        alt_headline: "🏆 Поиск рукописного Грааля идет полным ходом!",
        article_abstract: "🏢 Офис",
        author_name: "👨‍💻 Константин Скобельцын",
        backstory: "🎯 Определелили список призов",
        body: "<span style='font-weight: 300'>Мы создали призовой фонд хакатона - <b>Почерк</b>! 🏆 <ol style='list-style: none; padding: 0; font-weight: 300'><li><b>🥇 1 место</b> - Клавиатура Varmillo Panda ⌨️</li><li><b>🥈 2 место</b> - Клавиатура HyperX Alloy Mars 2 ⌨️</li><li><b>🥉 3 место</b> - Keychron K3 Blue Switch ⌨️</li><li><b>4️⃣ 4 место</b> - Мышь Razer Naga X 🖱️</li><li><b>5️⃣ 5 место</b> - Мышь Logitech G502 X Black 🖱️</li></ol></span>",
        date_of_publication: "2988-03-14T07:02:01Z",
        id: "5cff28a0-245d-4428-9bcf-4bdb70789555",
        keywords: "🎮 Хакатон; 🏅 Соревнования; 🤖 ИИ; 📊 Датасеты; 🎁 Призовой фонд",
        lead: "💎 Ценный лут!",
        section_title: "🎯 Хакатоны",
        thumbnail_url: "/api/uploads/hackathon3.png",
        title: "🎁 Призы"
    },
    {
        alt_headline: "Поиск рукописного Грааля запустился!",
        article_abstract: "Офис",
        author_name: "Константин Скобельцын",
        backstory: "Старт первого хакатона",
        body: "Первый хакатон по разметке данных запущен! <br><p>Тема хакатона - <b>Почерк</b>. <p>Нужно собрать и разметить датасет, состоящий из изображений рукописных слов.Результаты хакатона будут оформлены в открытый датасет.</p><p>Участников и призеров ждут ценные призы!</p><br/><p>Регистрация, уже доступна!</p><br><p>Хакатон продлится до 23:59&nbsp;26.11.2024!</p>",
        date_of_publication: "2988-03-14T07:02:01Z",
        id: "5cff28a0-245d-4428-9bcf-4bdb70789555",
        keywords: "Хакатон; Соревнования; ИИ; Датасеты",
        lead: "Хакатон запустился!",
        section_title: "Хакатоны",
        thumbnail_url: "/api/uploads/hackathon2.png",
        title: "Хакатон идет"
    },
    {
        alt_headline: "Поиск рукописного Грааля",
        article_abstract: "Офис",
        author_name: "Константин Скобельцын",
        backstory: "Старт первого хакатона",
        body: "Мы запускаем первый хакатон! Присоединяйся к нам и войди в мир ИИ!<br><p>Тебя ждет хакатон <b>Почерк</b>. Суть задачи проста - собрать и разметить датасет, состоящий из изображений рукописных слов. Датасеты будут открыты в общий доступ для обучения открытых моделей.</p><br/><p>Регистрация, уже доступна!</p><br><p>Хакатон начнется 14.11.2024 в 17:00 и продлится до 27 ноября!</p>",
        date_of_publication: "2988-03-14T07:02:01Z",
        id: "5cff28a0-245d-4428-9bcf-4bdb70789545",
        keywords: "Хакатон; Соревнования; ИИ; Датасеты",
        lead: "Мы запускаем первый хакатон!",
        section_title: "Хакатоны",
        thumbnail_url: "/api/uploads/hackathon1_with_logo.png",
        title: "Первый хакатон"
    },
]

export default class News extends React.Component<NewsPageContentProps, NewsPageContentState> {
    constructor(props: NewsPageContentProps) {
        super(props);
        this.state = {
            news: [],
            shownNewsIds: {},
            activeNewsArticle: null,
            filter: ""
        };
    }

    componentDidMount() {
        const url = "/api/news/index";

        this.setState({news: news});
        // this.props.api.asyncFetch(url, {}).then((response) => {
        //     const res = response.news_articles;
        //     this.setState({ news: res });
        // });
    }

    render() {
        const me = this;

        return <>
            <div className="news-page-content" onClick={(e) => {
                e.stopPropagation();
            }}>
                <div className={"news-page-content-circles"}>
                    {this.state.news.map((news, index) => {
                        return <div className="news-page-content-circle" key={index} onClick={() => {
                            me.setState({activeNewsArticle: news});
                        }}>
                            <img src={`${me.props.api.serverUrl}/${news.thumbnail_url}`} alt={news.title} />
                            <p>{news.title}</p>
                        </div>
                    })}
                </div>
                <div className="news-list">
                    {this.state.news.filter((x) => {
                        if (me.state.filter.startsWith("#")) {
                            let res = x.keywords.toString()
                                .split(";").map((x: string) => {
                                    return x.toLowerCase().trim();
                                });
                            console.log(res);
                            let filter = me.state.filter.toLowerCase().trim();
                            console.log(filter)
                            console.log(res.indexOf(filter))
                            return res.indexOf(filter.slice(1,1000000)) > -1;
                        }
                        return JSON.stringify(x).toLowerCase().indexOf(me.state.filter.toLowerCase()) !== -1;
                    }).map((news, index) => {
                        return <NewsItem api={this.props.api}
                                         news={news}
                                         key={index}
                                         filterCallback={(filter) => {
                                             me.setState({filter: `#${filter.trim()}`});
                                         }}
                        />

                    })}

                </div>

            </div>

            {me.state.activeNewsArticle && <ModalWindow onClick={() => {
                me.setState({activeNewsArticle: null});
            }}>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    style={{width: "90%", height: "80%", zIndex: 1000, paddingTop: "20px", paddingBottom: "20px", backgroundColor: "var(--white)", overflowY: "auto", overflowX: "hidden"}}>
                    <NewsItem api={this.props.api} news={me.state.activeNewsArticle} filterCallback={(filter) => {
                        me.setState({filter: `#${filter.trim()}`});
                    }} />
                </div>
            </ModalWindow>}
        </>;
    }
}