import React, { TouchEventHandler } from 'react';
import CubeMenu from "./components/cube_menu/CubeMenu";
import LaserText from "./components/cool_text/LaserText";
import LeftMenu from "./components/left_menu/LeftMenu";
import LawStuff from "./components/law_stuff/LawStuff";
import CustomCursor from "./components/custom_cursor/CustomCursor";
import AboutUs from "./pages/about_us/AboutUs";
import Contests from "./pages/contests/Contests";
import Events from "./pages/events/Events";
import Community from "./pages/community/Community";
import Registration from "./pages/registration/Registration";
import Grants from "./pages/grants/Grants";
import Education from "./pages/education/Education";
import RightPart from "./components/right_part/RightPart";
import Partners from "./pages/partners/Partners";
import Stars from "./components/cool_stuff/stars/Stars";
import MatrixEffect from "./components/cool_stuff/matrix_effect/MatrixEffect";
import HipsterText from "./components/cool_stuff/hipster_text/HipsterText";
import Worms from "./components/cool_stuff/worms/Worms";

const base = window.innerWidth < 510 ? `ИИ` : `Кластер_ИИ`;

export default class App extends React.Component<any, any> {
  initialized = false;
  cubeRef = React.createRef<CubeMenu>();
  data: any = [];
  startX: number = 0;
  startY: number = 0;
  endX: number = 0;
  endY: number = 0;
  dragging: boolean = false;

  constructor(props: any) {
    super(props);
    const me = this;
    this.state = {
      title: "Кластер_ИИ",
      activeSide: 1,
      sideNumber: 1
    };

    me.data = [
      [() => {me.setState({title: `${base}#О_нас`}, () => {me.setSide(0);}); }, "О_нас", "fas fa-info"],
      [() => {me.setState({title: `${base}#Хакатоны`}, () => {me.setSide(1);}); }, "Хакатоны", "fas fa-trophy"],
      [() => {me.setState({title: `${base}#Мероприятия`}, () => {me.setSide(2);}); }, "МероприятияСКРЫТЬ", "fas fa-calendar-alt"],
      [() => {me.setState({title: `${base}#Сообщество`}, () => {me.setSide(3);}); }, "Сообщество", "fas fa-users"],
      [() => {me.setState({title: `${base}#Стартапы`}, () => {me.setSide(4);}); }, "Стартапы", "fas fa-money-bill-wave"],
      // [() => {me.setState({title: `${base}#Образование`}, () => {me.setSide(5);}); }, "Образование", "fas fa-graduation-cap"],
    ];

    this.setSide = this.setSide.bind(this);
    this.startRegistration = this.startRegistration.bind(this);
    this.stopRegistration = this.stopRegistration.bind(this);
    this.draggingSlideLogic = this.draggingSlideLogic.bind(this);
  }

  startRegistration() {
    const me = this;
    me.setState({registration: true})
  }

  stopRegistration() {
    const me = this;
    me.setState({registration: false})
  }

  componentDidMount() {
    if (this.initialized) return;
    this.initialized = true;
    let me = this;
    // document.addEventListener("scroll", (_ev: Event) => {
    //   let y = window.scrollY;
    //   const incr = (window.innerWidth < 510) ? 0.5 : 1;
    //   let cubeNum = 6 * y / (incr * document.documentElement.scrollHeight);
    //   me.setSide(Math.floor(cubeNum));
    // });
    me.setSide(0);
  }

  setSide(_sideNumber: number) {
    const me = this;
    const activeCube = me.data[Math.floor(_sideNumber)] ? me.data[Math.floor(_sideNumber)][1] : me.data[Object.keys(me.data).length - 1][1];

    me.setState({activeSide: activeCube, title: `${base}#${activeCube}`, sideNumber: _sideNumber}, () => {
      me.cubeRef?.current?.setSide(_sideNumber + 1);
    });
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", () => {});
  }

  draggingSlideLogic(_e: React.TouchEvent<HTMLDivElement>) {
    const epsX = 90; 
    const epsY = 90;

    console.log(this.endX, this.endY);
    if (this.endX == 0 || this.endY == 0) {
      // _e.preventDefault();
      return;
    }
    // alert(this.startY + " " + this.endY + " " + epsY);
    let directionX = Math.abs( this.startX - this.endX) >= epsX ? Math.sign(this.startX - this.endX) : 0;
    let directionY = Math.abs(this.startY - this.endY) >= epsY ? Math.sign(this.startY - this.endY) : 0;

    let caseAsStr = directionX + " " + directionY + " " + this.state.sideNumber;
    
    console.log(caseAsStr);
    const cases = {
      '0 1 0': {
        action: () => this.setSide(1)
      },
      '0 -1 0': {
        action: () => this.setSide(4)
      },
      '0 1 1': {
        action: () => this.setSide(4)
      },
      '0 -1 1': {
        action: () => this.setSide(1)
      },
      '0 1 4': {
        action: () => this.setSide(0)
      },
      '0 -1 4': {
        action: () => this.setSide(1)
      },
      
      // '1 1 1': {
      //   action: () => this.setSide(4)
      // },
      '1 1 2': {
        action: () => this.setSide(0)
      },
      // '1 1 3': {
      //   action: () => this.setSide(4)
      // },
      // '1 1 4': {
      //   action: () => this.setSide(5)
      // },
      // '1 1 5': {
      //   action: () => this.setSide(6)
      // }
    } as any;
    if (cases[caseAsStr]) {
      cases[caseAsStr].action();
    }
  }

  render() {
    let me = this;
    return (
      <><div
          style={{display: "flex", height: "100%", background: "linear-gradient(30deg,  #2A197C, #00043C,  #3A1B58, #030303)"}}>
        {[0,2,5,6,7].indexOf(me.state.sideNumber) > -1 && <MatrixEffect /> }
        {me.state.sideNumber === 3 && <Stars/> }
        {me.state.sideNumber === 4 && <Worms/> }
        {me.state.sideNumber === 1 && <HipsterText text={"УЧАСТИЕ"} otherText={"примите участие, чтобы проверить свои силы + проверить свои навыки + научиться новому + найти друзей"}/> }
        <div style={{display: "flex", flexDirection: "column", width: window.innerWidth > 1000 ? "30vw" : 0}}>
          <LaserText key={me.state.title} text={me.state.title} subText={""}/>
           <RightPart/>
        </div>
        {/*<img style={{position: "fixed", top: "10vh", left: "0"}} src={pic}/>*/}

        <CubeMenu
          ref={me.cubeRef}
          activeSize={1}
          side1={
            <AboutUs setSide={me.setSide} />
          }
          side2={
            <Contests setSide={me.setSide} />
          }
          side3={
            <Events setSide={me.setSide} />
          }
          side4={
            <Community startRegistration={me.startRegistration}
                       setSide={me.setSide} />
          }
          side5={
            <Grants setSide={me.setSide}
                      startRegistration={me.startRegistration} />
          }
          side6={
            <Education startRegistration={me.startRegistration}
                       setSide={me.setSide} />
          }
        />
      {/* <Partners /> */}
        <CustomCursor/>
        { me.state.registration && <Registration stopRegistration={me.stopRegistration}/> }
        <LeftMenu elements={
          me.data
        } activeSide={me.state.activeSide}/>
        <LawStuff/>
      </div>
    </>
    );
  }
}

