import React from "react";
import "./style/modal-window.scss";

interface ModalWindowProps {
    children: JSX.Element[] | JSX.Element
    onClick: any
}

interface ModalWindowState {
    isVisible: boolean
}

export default class ModalWindow extends React.Component<ModalWindowProps, ModalWindowState> {
    constructor(_props: ModalWindowProps) {
        super(_props);
        this.state = {
            isVisible: false
        }
    }

    componentDidMount() {
        const me = this;
        setTimeout(() => {
            me.setState({
                isVisible: true
            })
        }, 200);
    }

    render() {
        const me = this;
        return <div onClick={() => {
            if (!me.props.onClick) return;
            me.setState({
                isVisible: false
            }, () => {
                setTimeout(() => {
                    me.props.onClick()
                }, 300);
            })
        }}
                    className={"modal-window"}
                    style={me.state.isVisible ? {pointerEvents: "all", opacity: 1} : {} }
        >
            <div className={"stripes"} />
            {me.props.children}
        </div>
    }
}