import React, { useEffect, useRef } from 'react';
// import * as dat from 'dat.gui';

const Worms: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
  const particlesRef = useRef<Particle[]>([]);
  const configRef = useRef({
    count: 300,
    mode: ["Radiance"],
    modes: ["Neo Hive Mind", "High Voltage", "Radiance"],
    spawnRange: 300,
    color1: "#c2fe13",
    color2: "#fff",
    clearColor: "rgba(0,0,0,0)",
    clean: function() {
      const ctx = ctxRef.current;
      const canvas = canvasRef.current;
      if (ctx && canvas) {
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = configRef.current.clearColor;
      }
    },
    radius: 0.8,
    alpha: 0.025,
    nt: 0
  });

  class Particle {
    x: number = 0;
    y: number = 0;
    s: number = 0;
    a: number = 0;
    radius: number = 0;
    color: string = '';

    constructor() {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const random = Math.random();
      this.x = canvas.width / 2 + (Math.random() * configRef.current.spawnRange - Math.random() * configRef.current.spawnRange);
      this.y = canvas.height / 2 + (Math.random() * configRef.current.spawnRange - Math.random() * configRef.current.spawnRange);
      this.s = Math.random() * 1;
      this.a = 0;
      this.radius = random > .2 ? Math.random() * 1 : Math.random() * 3;
      this.radius = random > .8 ? Math.random() * 2 : this.radius;
      this.color = random > .5 ? configRef.current.color1 : configRef.current.color2;
    }

    render() {
      const ctx = ctxRef.current;
      if (!ctx) return;

      ctx.beginPath();
      ctx.arc(this.x, this.y, this.radius * configRef.current.radius, 0, 2 * Math.PI);
      ctx.fillStyle = this.color;
      ctx.fill();
      ctx.closePath();
    }

    move() {
      const canvas = canvasRef.current;
      if (!canvas) return false;

      this.x += Math.cos(this.a) * this.s;
      this.y += Math.sin(this.a) * this.s;
      this.a += Math.random() * 0.4 - 0.2;

      if (this.x < 0 || this.x > canvas.width - this.radius) {
        if (configRef.current.count >= particlesRef.current.length) {
          emit(1);
        }
        return false;
      }

      if (this.y < 0 || this.y > canvas.height - this.radius) {
        if (configRef.current.count >= particlesRef.current.length) {
          emit(1);
        }
        return false;
      }

      this.render();
      return true;
    }
  }

  const reset = () => {
    const canvas = canvasRef.current;
    const ctx = ctxRef.current;
    if (!canvas || !ctx) return;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = configRef.current.clearColor;
  };

  const emit = (num: number) => {
    for (let i = 0; i < num; i++) {
      setTimeout(() => {
        particlesRef.current.push(new Particle());
      }, i * 50);
    }
    return particlesRef.current.length;
  };

  const clear = () => {
    const ctx = ctxRef.current;
    const canvas = canvasRef.current;
    if (!ctx || !canvas) return;

    ctx.globalAlpha = configRef.current.alpha;
    ctx.fillStyle = configRef.current.clearColor;
    // ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.globalAlpha = 1;
  };

  const update = () => {
    const ctx = ctxRef.current;
    if (!ctx) return;

    ctx.globalCompositeOperation = "lighter";
    particlesRef.current = particlesRef.current.filter(p => p.move());
    ctx.globalCompositeOperation = "source-over";
    clear();
    requestAnimationFrame(update);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    ctxRef.current = canvas.getContext('2d');
    
    // const gui = new dat.GUI();
    // gui.add(configRef.current, "count", 10, 1000).step(1).name("Count");
    // gui.add(configRef.current, "spawnRange", 10, 500).step(1).name("Spawn Area");
    // gui.add(configRef.current, "radius", 0.2, 3).step(0.1).name("Radius");
    // gui.add(configRef.current, "alpha", 0.005, 0.2).step(0.001).name("Alpha");
    // gui.addColor(configRef.current, "color1").name("Color 1");
    // gui.addColor(configRef.current, "color2").name("Color 2");
    // gui.addColor(configRef.current, "clearColor").name("Clear Color");
    // gui.add(configRef.current, "clean").name("Clean Up");
    // gui.close();

    reset();
    window.addEventListener("resize", reset);
    emit(configRef.current.count);
    update();

    return () => {
      window.removeEventListener("resize", reset);
      // gui.destroy();
    };
  }, []);

  return (
    <div className="worms">
        <canvas 
        ref={canvasRef}
        id="canvas"
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
        }}
        />
    </div>
  );
};

export default Worms;