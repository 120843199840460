import React from "react";
import "./contests.scss";
import LedNumber from "../../components/cool_stuff/led_number/LedNumber";
import GlitchHeader from "../../components/cool_stuff/glitch_header/GlitchHeader";
import ModalWindow from "../../components/modal/ModalWindow";
import TypedText from "../../components/cool_stuff/typed_text/TypedText";


const upcomingHackathons = [
] as any[];

const finishedHackathons = [
  {
    name: "Почерк",
    hackatonType: "Разметка",
    description: "В рамках хакатона разметьте изображения рукописного текста и создайте самый качественный датасет для обучения нейронных сетей",
    date: "2024-11-14",
    prizes: [
      {
        name: "1 место",
        amount: "100 000 ₽"
      },
      {
        name: "2 место",
        amount: "50 000 ₽"
      },
      {
        name: "3 место",
        amount: "30 000 ₽"
      }
    ]
  },
];

export default class Contests extends React.Component<any, any> {

  constructor(_props: any) {
    super(_props);
    this.state = {
      upcomingHackathons: upcomingHackathons,
      finishedHackathons: finishedHackathons,
      showHackathons: false,
      showFinishedHackathons: false,
    };
  }

  render() {
    const me = this;

    return <><div className={"contests"}>
      <GlitchHeader content={"Контесты и хакатоны"} />
      <table>
        <tbody>
        <tr>
          <td>
            <div style={{width: "80px"}}>
              <span>АКТИВНЫЕ:</span>
              { me.state.upcomingHackathons.length &&
                <button style={{padding: "0px", width: "100%", fontSize: "12px", marginTop: "2px"}}
                        className="sport-inv"
                        onClick={() => {
                          me.setState({showHackathons: !me.state.showHackathons});
                        }}
                >Смотреть
                </button>
              }
            </div>
          </td>
          <td><LedNumber number={me.state.upcomingHackathons.length} size={23}/></td>

        </tr>
        {
            me.state.showHackathons &&
            <tr onClick={() => {
              me.setState({showHackathons: false});
            }}>
              <td colSpan={3} style={{padding: 0}}>
                <div className={"upcoming-hackathons"}>
                  {
                    me.state.upcomingHackathons.map((_hackathon: any) => {
                      return <div className={"upcoming-hackathon"}>
                        <h3>{_hackathon.name}</h3>
                        {/* <p>{_hackathon.description}</p> */}
                        <p>{_hackathon.date}</p>
                        <TypedText message={_hackathon.description}/>
                        <button onClick={() => {
                          me.props.setSide(3);
                        }}>ПРИСОЕДИНИТЬСЯ
                        </button>
                      </div>
                    })
                  }
                </div>
              </td>
            </tr>
        }
        <tr>
          <td>ПРЕДСТОЯЩИЕ:</td>
          <td><LedNumber number={1} size={23}/></td>
        </tr>
        <tr>
          <td>
            <div style={{width: "80px"}}>
              <span>ЗАВЕРШЕННЫЕ:</span>
              { me.state.finishedHackathons.length &&
                  <button style={{padding: "0px", width: "100%", fontSize: "12px", marginTop: "2px"}}
                          className="sport-inv"
                          onClick={() => {
                            me.setState({showFinishedHackathons: !me.state.showFinishedHackathons});
                          }}
                  >Смотреть
                  </button>
              }
            </div>
          </td>
          <td><LedNumber number={me.state.finishedHackathons.length} size={23}/></td>
        </tr>
        {
            me.state.showFinishedHackathons &&
            <tr onClick={() => {
              me.setState({showFinishedHackathons: false});
            }}>
              <td colSpan={3} style={{padding: 0}}>
                <div className={"upcoming-hackathons"}>
                  {
                    me.state.finishedHackathons.map((_hackathon: any) => {
                      return <div className={"upcoming-hackathon"}>
                        <h3>{_hackathon.name}</h3>
                        {/* <p>{_hackathon.description}</p> */}
                        <p>{_hackathon.date}</p>
                        <TypedText message={_hackathon.description}/>
                      </div>
                    })
                  }
                </div>
              </td>
            </tr>
        }
        </tbody>
      </table>

      <button onClick={() => {
        me.props.setSide(3);
      }} style={{marginTop: "14px", background: "var(--sport-lime)", color: "var(--dark-black)"}}>УЧАСТВОВАТЬ</button>
      <button onClick={() => {me.props.setSide(2);}} style={{ marginTop: "14px", color: "var(--white-black)", background: "var(--dark-blue)"}}>МЕРОПРИЯТИЯ</button>

    </div>
    </>
  }
}