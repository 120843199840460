import "./cube-menu.scss"
import React from "react";

interface CubeMenuProps {
  side1: JSX.Element;
  side2: JSX.Element;
  side3: JSX.Element;
  side4: JSX.Element;
  side5: JSX.Element;
  side6: JSX.Element;
  activeSize: number;
}

interface CubeMenuState {
  activeSide: number;
  amountOfRotationX: number;
  amountOfRotationY: number;
  amountOfRotationZ: number;
  rotationAngle: number;
}
const ebanutost = 3;
const sides = {
  "1": {
    numbers: [0,-0.1,0,0 + ebanutost * 0.01],
    transform: "rotate3d(0, 0, 0, 90deg)"
  },
  "2": {
    numbers: [1,0,0,90 + ebanutost],
    transform: "rotate3d(1, 0, 0, 90deg)"
  },
  "3": {
    numbers: [0,1,0,90 + ebanutost],
    transform: "rotate3d(0, 1, 0, 90deg)"
  },
  "4": {
    numbers: [0,1,0,-90 + ebanutost],
    transform: "rotate3d(0, 1, 0, -90deg)"
  },
  "5": {
    numbers: [1,0.01,0.01,-90 - ebanutost],
    transform: "rotate3d(1, 0, 0, -90deg)"
  },
  "6": {
    numbers: [0,1,0,180 + ebanutost],
    transform: "rotate3d(0, 1, 0, 180deg)"
  }
} as any;

export default class CubeMenu extends React.Component<CubeMenuProps, CubeMenuState> {
  dragging: boolean = false;
  startX: number = 0;
  startY: number = 0;
  endX: number = 0;
  endY: number = 0;
  constructor(_props: CubeMenuProps) {
    super(_props);
    this.state = {
      activeSide: 1,
      amountOfRotationX: 0,
      amountOfRotationY: 0,
      amountOfRotationZ: 0,
      rotationAngle: ebanutost
    };

    this.setSide = this.setSide.bind(this);
  }

  setSide(_sideNumber: number) {
    console.log(_sideNumber);

    let rotationParams = sides[Math.floor(_sideNumber) + ""];

    if (!rotationParams) {
      return;
    }

    this.setState({
      amountOfRotationX: rotationParams.numbers[0],
      amountOfRotationY: rotationParams.numbers[1],
      amountOfRotationZ: rotationParams.numbers[2],
      rotationAngle: rotationParams.numbers[3] + ebanutost,
      activeSide: _sideNumber
    }, () => {
      // alert(_sideNumber);
    });
  }

  

  render() {
    let me = this;

    return <div className={"cube"}
                
                // style={sides[Math.round(me.state.activeSide)+""]}>
                style={{ transform: `rotate3d(${me.state.amountOfRotationX}, ${me.state.amountOfRotationY}, ${me.state.amountOfRotationZ}, ${me.state.rotationAngle}deg)`}}>
      <div style={ 5 === me.state.activeSide ? {pointerEvents: "all"} : {pointerEvents: "none", filter: "brightness(0.3)", transition: "1500ms"}} className={"face top"}>{me.props.side5}</div>
      <div style={ 2 === me.state.activeSide ? {pointerEvents: "all"} : {pointerEvents: "none", filter: "brightness(0.3)", transition: "1500ms"}} className={"face bottom"}>{me.props.side2}</div>
      <div style={ 3 === me.state.activeSide ? {pointerEvents: "all"} : {pointerEvents: "none", filter: "brightness(0.3)", transition: "1500ms"}} className={"face left"}>{me.props.side3}</div>
      <div style={ 4 === me.state.activeSide ? {pointerEvents: "all"} : {pointerEvents: "none", filter: "brightness(0.3)", transition: "1500ms"}} className={"face right"}>{me.props.side4}</div>
      <div style={ 1 === me.state.activeSide ? {pointerEvents: "all"} : {pointerEvents: "none", filter: "brightness(0.3)", transition: "1500ms"}} className={"face front"}>{me.props.side1}</div>
      <div style={ 6 === me.state.activeSide ? {pointerEvents: "all"} : {pointerEvents: "none", filter: "brightness(0.3)", transition: "1500ms"}} className={"face back"}>{me.props.side6}</div>
    </div>
  }
  
}