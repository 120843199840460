import React from "react";
import "./grants.scss";
import LedNumber from "../../components/cool_stuff/led_number/LedNumber";
import GlitchHeader from "../../components/cool_stuff/glitch_header/GlitchHeader";
import focusImg from "./static/focus.svg";
import viizualImg from "./static/viizual.svg";

export default class Grants extends React.Component<any, any> {
  render() {
    const me = this;
    return <div className={"grants"}>
      <GlitchHeader content={"Стартапы"} />
      {/* <div className={"flex flex-row justify-between items-center content-center"}>
        <p>Проектов</p>
        <LedNumber number={0} size={30} />
      </div> */}
      <br/>
      <br/>
      <div className={"flex flex-column justify-between"}>
        <div className={"project"}>
          <p className={"title"}>FOCUS24</p>
          <img src={focusImg}/>
          <div className={"description"}>
            <p className={"lead"}>Сканер уровня производительности команды
            с помощью машинного зрения.</p>
            <p className={"text"}>Приложение измеряет внимание и утомленность, анализирует динамику, и выдает прогноз с советами для для выполнения задач наиболее эффективно.</p>
          </div>
          <a className={"link"} href={"https://focus24.pro/"} target="_blank"><i className={"fa fa-globe"}/></a>
        </div>
        <div className={"project"}>
          <p className={"title"}>вИИзуал</p>
          <img src={viizualImg}/>
          <div className={"description"}>
            <p className={"lead"}>Инструмент для информированности во всем в компании с помощью ИИ</p>
            <p className={"text"}>Приложение измеряет внимание и утомленность, анализирует динамику, и выдает прогноз с советами для для выполнения задач наиболее эффективно.</p>
          </div>
          <a className={"link"} href={"https://viizual.tech/"} target="_blank"><i className={"fa fa-globe"}/></a>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
    </div>
  }
}