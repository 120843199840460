import React from "react";
import "./law_stuff.scss";
import PrivacyPolicy from "../../pages/privacy_policy/PrivacyPolicy";
import ModalWindow from "../modal/ModalWindow";
const links = [
  ["Политика конфиденциальности", "#"],
  // ["Правила пользования", "#"],
  // ["Политика возврата средств", "#"],
] as any[];
export default class LawStuff extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      openedLink: null
    }
  }

  render() {
    const me = this;
    return <><div className={"lawstuff"}>
      {
        links.map((_l, _lIndex) => {
          return <p key={`link-${_lIndex}`} onClick={() => {
            me.setState({openedLink: _l[0]})
          }

            }>{_l[0]}</p>;
      })
      }
    </div>
      { me.state.openedLink === "Политика конфиденциальности" &&
      <ModalWindow onClick={() => me.setState({openedLink: null})}><PrivacyPolicy/></ModalWindow>}
    </>
  }
}