import React from "react";

interface TypedTextProps {
  message: string;
  onFinishTyping?: () => void;
}

interface TypedTextState {
  displayedMessage: string;
  currentIndex: number;
}

export default class TypedText extends React.Component<TypedTextProps, TypedTextState> {
  typingTimer: any;

  constructor(props: TypedTextProps) {  
    super(props);
    this.state = {
      displayedMessage: "",
      currentIndex: 0
    };
  }

  componentDidMount() {
    this.startTypingAnimation();
  }

  componentWillUnmount() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }
  startTypingAnimation = () => {
    const typeNextChar = () => {
      if (this.state.currentIndex < this.props.message.length) {
        this.setState({
          displayedMessage: this.props.message.slice(0, this.state.currentIndex + 1),
          currentIndex: this.state.currentIndex + 1
        }, () => {
          this.typingTimer = setTimeout(typeNextChar, 10);
        });
      }
      if (this.props.onFinishTyping) {
        this.props.onFinishTyping();
      }
    };
    this.typingTimer = setTimeout(typeNextChar, 1);
  }

  render() {
    return <p className={"announcement"}>{this.state.displayedMessage}</p>
  }
}