import React from 'react';
import "./style/hipster-text.scss";

interface HipsterTextProps {
  text: string;
  otherText: string;
}

const HipsterText: React.FC<HipsterTextProps> = ({ text, otherText }) => {
  return (
    <>
      <div className="hipster-text">
        {/* <img className="bg_silhouette" src="https://assets.codepen.io/1692350/fly_bg.png" alt="" /> */}
        {/*<canvas id="canvas"></canvas>*/}
        <div className="bg-stuff">
          <div className="text-left"> + {otherText} +
          </div>
          <div className="line-left-1">
            <div className="l-big1"></div>
            <div className="l-big2"></div>
            <div className="l-small1"></div>
            <div className="l-small2"></div>
            <div className="l-small3"></div>
            <div className="l-plus1"></div>
            <div className="l-plus2"></div>
          </div>
          <div className="l-full"></div>
          <div className="bg-text">
            <div className="fly">{text || "fly by night"}</div>
            <div className="fly">{text || "fly by night"}</div>
            <div className="fly fly-fill">{text || "fly by night"}</div>
            <div className="fly">{text || "fly by night"}</div>
            <div className="fly">{text || "fly by night"}</div>
            <div className="fly">{text || "fly by night"}</div>
            <div className="fly">{text || "fly by night"}</div>
            <div className="fly">{text || "fly by night"}</div>
            <div className="fly fly-fill">{text || "fly by night"}</div>
            <div className="fly">{text || "fly by night"}</div>
            <div className="fly">{text || "fly by night"}</div>
            <div className="fly">{text || "fly by night"}</div>
            <div className="fly">{text || "fly by night"}</div>
            <div className="fly">{text || "fly by night"}</div>
          </div>

          <div className="tc-dots tc-dots-1">
            <div className="tc-dot tc-square tc-dot1"></div>
            <div className="tc-dot tc-square tc-dot2"></div>
            <div className="tc-dot tc-square tc-dot3"></div>
            <div className="tc-dot tc-square tc-dot4"></div>
            <div className="tc-dot tc-square tc-dot5"></div>
            <div className="tc-dot tc-square tc-dot6"></div>
            <div className="tc-dot tc-square tc-dot7"></div>
            <div className="tc-dot tc-square tc-dot8"></div>
            <div className="tc-dot tc-square tc-dot9"></div>
          </div>

          <div className="data-bars">
            <div className="data-bar data-bar1"></div>
            <div className="data-bar data-bar2"></div>
            <div className="data-bar data-bar3"></div>
          </div>

        </div>
      </div>
    </>
  );
};

export default HipsterText;